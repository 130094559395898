import React, {
  useState,
  useEffect
} from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Link,
  Portal,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    maxWidth: '100%',
    height: 'auto',
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  }
}));

function VerifyMention() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.account);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!user.verified) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography
          variant="body1"
          color="inherit"
        >
          {t('verifyMention1', 'You haven\'t verified your email yet! Please check your email for your verification link or')}
          {' '}
          {user.email}{'.'}
          {' '}
          {/* <Link
            component="a"
            color="inherit"
            underline="always"
            href="#"
            target="_blank"
          >
            {t('verifyMention2', 'click here')}
          </Link>
          {' '} */}
          {t('verifyMention3', 'to resend the verification email, which includes the download to our Android app, too!')}
          {' '}
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.action}
          >
            {t('verifyMention4', 'Okay')}
          </Button>
        </Typography>
      </div>
    </Portal>
  );
}

export default VerifyMention;
