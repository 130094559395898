import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import PoweredByIcon from 'src/components/PoweredByIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 16
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

function CTA({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Ste pripravený na používanie?
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="secondary"
        >
          Kontaktujte nás:
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <PoweredByIcon />
        </Box>
        <Box
          mt={1}
          mb={8}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            color="black"
            component="a"
            href="mailto: info@mynotifio.com"
            variant="contained"
          >
            info@mynotifio.com
          </Button>
        </Box>
        <Box
          mt={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        <Typography
          variant="h6"
          align="center"
          color="black"
        >
          Notifio s.r.o., Nemesšegská 5, 929 01, Dunajská Streda, Slovakia
        </Typography>
        </Box>
        <Box
          mt={0}
          mb={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="black"
        >
          <Link variant="h6" href='https://www.mynotifio.com' target="_blank" >
            https://mynotifio.com
          </Link>
        </Box>
      </Container>
    </div>
  );
}

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;
