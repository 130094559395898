/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const LANGUAGES = [
  { key: 'en', value: 'English' },
  { key: 'sk', value: 'Slovak' },
  { key: 'hu', value: 'Hungarian' },
  { key: 'at', value: 'Austrian-German' },
  { key: 'de', value: 'German' },
  { key: 'se', value: 'Swedish' },
  { key: 'no', value: 'Norwegian' }
];
