/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  // Grid
} from '@material-ui/core';
import {
  Home as HomeIcon,
  User as UserIcon,
  Users as UsersIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import PoweredByIcon from 'src/components/PoweredByIcon';
import NavItem from './NavItem';
import i18n from 'src/i18n';
import Can from 'src/components/Can'

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => {
          if (item.hasOwnProperty('render') && item.render) {
            return reduceChildRoutes({ acc, item, ...rest })
          } else if (!item.hasOwnProperty('render')) {
            return reduceChildRoutes({ acc, item, ...rest })
          } else {
            return acc
          }
        },
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const navConfig = [
    {
      subheader: i18n.t('representantPanel', 'Representant Panel'),
      perform: 'user:representant',
      items: [
        {
          title: i18n.t('representantView', 'Representant Page'),
          icon: UserIcon,
          href: '/app/reports/representant',
        },
      ]
    },

    {
      subheader: i18n.t('establishment', 'Establishments'),
      perform: 'hasEstablishment',
      items: [
        {
          title: i18n.t('myEstablishment', 'My Establishment'),
          render: user.establishments.length == 1,
          icon: HomeIcon,
          href: user.establishments.length == 0 ? `/app/myestablishments` : `/app/establishment/${user.establishments[0].establishment}`,
        },
        {
          title: i18n.t('myEstablishments', 'My Establishments'),
          render: user.establishments.length > 1,
          icon: HomeIcon,
          href: `/app/myestablishments`,
        },
      ]
    },

    {
      subheader: i18n.t('personalSettings', 'Personal Settings'),
      perform: 'user:customer',
      items: [
        {
          title: i18n.t('accountPage', 'Account Page'),
          icon: UserIcon,
          href: '/app/account',
        },
      ]
    },

    {
      subheader: i18n.t('adminPanel', 'Admin Panel'),
      perform: 'user:admin',
      items: [
        {
          title: i18n.t('establishments', 'Establishments'),
          icon: HomeIcon,
          href: '/app/management/establishment',
        },
        // {
        //   title: i18n.t('establishmentFeed', 'Establishment Feed'),
        //   icon: ShareIcon,
        //   href: '/app/social/feed',
        // },
        {
          title: i18n.t('users', 'Users'),
          icon: UsersIcon,
          href: '/app/management/customers',
        },
        {
          title: i18n.t('addUserToEst', 'Add User to Establishment'),
          icon: UsersIcon,
          href: '/app/management/establishment/adduser',
        },
        {
          title: i18n.t('addClientToEst', 'Add Client to Establishment'),
          icon: UsersIcon,
          href: '/app/management/establishment/addclient',
        },
        // {
        //   title: i18n.t('preferences', 'Preferences'),
        //   icon: FolderIcon,
        //   href: '/app/management/preferences',
        // },
      ]
    },
  ]

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account"
              style={{ textDecoration: 'none' }}>
              <Avatar
                alt="User"
                className={classes.avatar}
                src={`/image/logo/${user.avatar}.jpeg`}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstName} ${user.lastName}`}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <Can
              role={user.roles}
              perform={config.perform}
              data={{ userEstablishmentId: user.establishments.establishment }}
              yes={() =>
                <List
                  key={config.subheader}
                  subheader={(
                    <ListSubheader
                      disableGutters
                      disableSticky
                    >
                      {config.subheader}
                    </ListSubheader>
                  )}
                >
                  {renderNavItems({ items: config.items, pathname: location.pathname })}
                </List>
              }
              no={() => null}
            />
          ))}

        </Box>
        <Divider />
        {/* <Grid
          container
          direction="column"
          justify="flex-end"
          alignItems="center"
        > */}
        <Box alignItems="flex-end" justifyContent="flex-end" p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            {/* <Grid> */}
            <Typography
              variant="overline"
              color="textPrimary"
            >
              {/* Powered by:{"\n"} */}
            </Typography>
            {/* </Grid>
              <Grid> */}
            <PoweredByIcon />
            {/* </Grid>
              <Grid> */}
            <Typography
              variant="caption"
              color="secondary"
            >{"\n"}
              {/* info@mynotifio.com */}
              support@mynotifio.com
            </Typography>
            {/* </Grid> */}
          </Box>
        </Box>
        {/* </Grid> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
