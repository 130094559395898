import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Hidden,
  Typography,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import Account from '../DashboardLayout/TopBar/Account';
import Language from '../DashboardLayout/TopBar/Language';
import Settings from '../DashboardLayout/TopBar/Settings';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

function TopBar({ className, establishment, ...rest }) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.account);
  const { t } = useTranslation();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/home">
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            myNotifio
          </Typography>
        </Hidden>
        {!user ? null :
          <>
            <Box ml={2} />
            <Button
              className={classes.link}
              component={RouterLink}
              to={`/redirect`}
              size="small"
            >
              {t('back', 'Go Back')}
            </Button>
          </>}
        <Box flexGrow={1} />
        <Language />
        {!user ? null : <Settings />}
        {!user ? <Button
          className={classes.link}
          component={RouterLink}
          to="/"
          underline="none"
          color="secondary"
          variant="contained"
          size="small"
        >
          {t('auth:logInPage.logIn', 'Log In')}
        </Button> :
          <Box ml={2}>
            <Account />
          </Box>}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
