const rules = {
    Guest: {
        static: [
            "user:guest",
        ],
    },
    Customer: {
        static: [
            "user:guest",
            "user:customer",
            "hasEstablishment",
        ],
        dynamic: {
            "comment:create": ({ userId, establishmentId }) => {
                if (!userId || !establishmentId) return false;
                return userId === establishmentId;
            },
            "comment:edit": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "comment:delete": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "reaction:do": ({ userId, establishmentId }) => {
                if (!userId || !establishmentId) return false;
                return userId === establishmentId;
            },
            "user:customer:establishment": ({ userEstablishmentId, establishmentId }) => {
                if (!userEstablishmentId || !establishmentId) return false;
                return userEstablishmentId === establishmentId;
            }
        }
    },
    Client: {
        static: [
            "user:guest",
            "user:customer",
            "user:client",
            "hasEstablishment",
        ],
        dynamic: {
            "notificationPost:create": ({ userEstablishmentId, establishmentId }) => {
                if (!userEstablishmentId || !establishmentId) return false;
                return userEstablishmentId === establishmentId;
            },
            "user:client:settings": ({ userEstablishmentId, establishmentId }) => {
                if (!userEstablishmentId || !establishmentId) return false;
                return userEstablishmentId === establishmentId;
            },
            "notificationPost:edit": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "notificationPost:delete": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "comment:create": ({ userId, establishmentId }) => {
                if (!userId || !establishmentId) return false;
                return userId === establishmentId;
            },
            "comment:edit": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "comment:delete": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "reaction:do": ({ userId, establishmentId }) => {
                if (!userId || !establishmentId) return false;
                return userId === establishmentId;
            },
            "user:customer:establishment": ({ userEstablishmentId, establishmentId }) => {
                if (!userEstablishmentId || !establishmentId) return false;
                return userEstablishmentId === establishmentId;
            }
        }
    },
    Representant: {
        static: [
            "user:guest",
            "user:customer",
            "user:client",
            "user:representant",
        ],
        dynamic: {
            "notificationPost:create": ({ userId, establishmentId }) => {
                if (!userId || !establishmentId) return false;
                return userId === establishmentId;
            },
            "notificationPost:edit": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "notificationPost:delete": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "comment:create": ({ userId, establishmentId }) => {
                if (!userId || !establishmentId) return false;
                return userId === establishmentId;
            },
            "comment:edit": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "comment:delete": ({ userId, postOwnerId }) => {
                if (!userId || !postOwnerId) return false;
                return userId === postOwnerId;
            },
            "reaction:do": ({ userId, establishmentId }) => {
                if (!userId || !establishmentId) return false;
                return userId === establishmentId;
            },
            "user:customer:establishment": ({ userEstablishmentId, establishmentId }) => {
                if (!userEstablishmentId || !establishmentId) return false;
                return userEstablishmentId === establishmentId;
            }
        }
    },
    Admin: {
        static: [
            "user:guest",
            "user:customer",
            "user:customer:establishment",
            "user:client",
            "user:representant",
            "user:admin",
            "user:client:settings",
            "notificationPost:create",
            "notificationPost:edit",
            "notificationPost:delete",
            "comment:create",
            "comment:edit",
            "comment:delete",
            "reaction:do",
            "hasEstablishment"
        ]
    }
};

export default rules;