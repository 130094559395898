/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment,
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
// import MainLayoutGuest from 'src/layouts/MainLayoutGuest';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/redirect" />
  },
  {
    exact: true,
    path: '/redirect',
    component: lazy(() => import('src/views/pages/Redirect'))
  },
  {
    exact: true,
    path: '/noestablishments',
    component: lazy(() => import('src/views/pages/NoEstablishments'))
  },
  {
    exact: true,
    path: '/waiting',
    component: lazy(() => import('src/views/pages/Waiting'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgotpassword',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/newpassword/:token',
    component: lazy(() => import('src/views/auth/NewPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/verify/:token',
    component: lazy(() => import('src/views/auth/Verify'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to={`/app/account`} />
      },
      {
        exact: true,
        path: '/app/blank',
        component: lazy(() => import('src/views/blankpage'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/representant',
        component: lazy(() => import('src/views/social/RepresentantView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/account" />
      },

      {
        exact: true,
        path: '/app/myestablishments',
        component: lazy(() => import('src/views/management/CustomerEstablishmentListView'))
      },
      {
        exact: true,
        path: '/app/establishment/:establishmentId/notification/:postId',
        component: lazy(() => import('src/views/social/PostView'))
      },
      {
        exact: true,
        path: '/app/establishment/:establishmentId',
        component: lazy(() => import('src/views/social/EstablishmentView'))
      },

      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/management/CustomerListView'))
      },
      // {
      //   exact: true,
      //   path: '/app/management/customers/search',
      //   component: lazy(() => import('src/views/management/CustomerSearchView'))
      // },
      {
        exact: true,
        path: '/app/management/customers/new',
        component: lazy(() => import('src/views/management/CustomerCreateView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/management/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/management/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/establishment',
        component: lazy(() => import('src/views/management/EstablishmentListView'))
      },
      {
        exact: true,
        path: '/app/management/establishment/new',
        component: lazy(() => import('src/views/management/EstablishmentCreateView'))
      },
      {
        exact: true,
        path: '/app/management/establishment/addclient',
        component: lazy(() => import('src/views/management/EstablishmentAddClientView'))
      },
      {
        exact: true,
        path: '/app/management/establishment/adduser',
        component: lazy(() => import('src/views/management/EstablishmentAddUserView'))
      },
      {
        exact: true,
        path: '/app/management/establishment/:establishmentId',
        component: lazy(() => import('src/views/management/EstablishmentDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/establishment/:establishmentId/edit',
        component: lazy(() => import('src/views/management/EstablishmentEditView'))
      },

      // {
      //   exact: true,
      //   path: '/app/management/preferences',
      //   component: lazy(() => import('src/views/management/PreferenceListView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/preferences/new',
      //   component: lazy(() => import('src/views/management/PreferenceCreateView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/preferences/:preferenceId',
      //   component: lazy(() => import('src/views/management/PreferenceDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/preferences/:preferenceId/edit',
      //   component: lazy(() => import('src/views/management/PreferenceEditView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/groups',
      //   component: lazy(() => import('src/views/management/GroupListView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/groups/new',
      //   component: lazy(() => import('src/views/management/GroupCreateView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/groups/:groupId',
      //   component: lazy(() => import('src/views/management/GroupDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/app/management/groups/:groupId/edit',
      //   component: lazy(() => import('src/views/management/GroupEditView'))
      // },

      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },

      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },

      // {
      //   exact: true,
      //   path: '/app/social/profile',
      //   component: lazy(() => import('src/views/social/ProfileView'))
      // },

      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/account" />
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/gdpr/marketing',
        component: lazy(() => import('src/views/pages/GDPR/Marketing/index'))
      },
      {
        exact: true,
        path: '/gdpr/privacy',
        component: lazy(() => import('src/views/pages/GDPR/Privacy/index'))
      },
      {
        exact: true,
        path: '/gdpr/terms',
        component: lazy(() => import('src/views/pages/GDPR/Terms/index'))
      },
      {
        exact: true,
        path: '/:establishmentId',
        component: lazy(() => import('src/views/pages/EstablishmentPublicPage'))
      },
      {
        exact: true,
        path: '/:establishmentId/register',
        component: lazy(() => import('src/views/pages/EstablishmentRegisterPage'))
      },
      {
        exact: true,
        path: '/:establishmentId/gdpr/privacy',
        component: lazy(() => import('src/views/pages/GDPR/PrivacyInsert/index'))
      },
      {
        exact: true,
        path: '/:establishmentId/:establishmentId/gdpr/privacy',
        component: lazy(() => import('src/views/pages/GDPR/PrivacyInsert/index'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
