import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import common_en from "./translations/en/common.json";
import auth_en from "./translations/en/auth.json";
import common_sk from "./translations/sk/common.json";
import auth_sk from "./translations/sk/auth.json";
import common_se from "./translations/se/common.json";
import auth_se from "./translations/se/auth.json";
import common_hu from "./translations/hu/common.json";
import auth_hu from "./translations/hu/auth.json";
import common_cz from "./translations/cz/common.json";
import auth_cz from "./translations/cz/auth.json";
import common_at from "./translations/at/common.json";
import auth_at from "./translations/at/auth.json";
import common_de from "./translations/de/common.json";
import auth_de from "./translations/de/auth.json";
import common_no from "./translations/no/common.json";
import auth_no from "./translations/no/auth.json";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                common: common_en,
                auth: auth_en
            },
            sk: {
                common: common_sk,
                auth: auth_sk
            },
            se: {
                common: common_se,
                auth: auth_se
            },
            hu: {
                common: common_hu,
                auth: auth_hu
            },
            cz: {
                common: common_cz,
                auth: auth_cz
            },
            at: {
                common: common_at,
                auth: auth_at
            },
            de: {
                common: common_de,
                auth: auth_de
            },
            no: {
                common: common_no,
                auth: auth_no
            },
        },
        // lng: "sk",
        ns: ['common'],
        defaultNS: 'common',
        debug: true,
        saveMissing: true,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;